<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Sales") }}</label>
      <span>/</span>
      <label active>{{ $t("StopSubscriptions") }}</label>
    </div>

    <!-- Start Dialog Stop -->
    <v-dialog v-model="dialogStop" width="50rem">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StopSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.start_date`),
                  }"
                >
                  <label>{{ $t("StartDate") }}</label>

                  <el-date-picker
                    style="width: 100%"
                    v-model="stopForm.start_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('StartDate')"
                    class="d-block my-2"
                    name="start_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.start_date`)"
                >
                  {{ errors.first(`addEditValidation.start_date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                  <label>{{ $t("EndDate") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="stopForm.end_date"
                    type="date"
                    class="d-block my-2"
                    name="end_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStop = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStopConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start Dialog Start -->
    <v-dialog v-model="dialogStart" width="50rem">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StartSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="startForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>


                <v-col class="py-0" :cols="4">
                  <label>{{ $t("StopStartDate") }}</label>
                  <el-date-picker
                   v-if="startItem && 
                   startItem.stop_subscription &&
                    startItem.stop_subscription.start_date &&
                     startItem.stop_subscription.start_date !== null
                     "
                    style="width: 100%"
                    :value="startItem.stop_subscription.start_date"
                    type="date"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    readonly
                    outlined
                  >
                  </el-date-picker>
                   <v-else v-if="startItem && startItem.stop_subscription && startItem.stop_subscription.start_date == null">
                    <h4 class="mt-5" style="color:red">{{$t('NoStopStartDate')}}</h4>
                  </v-else>

              </v-col>


                <v-col class="py-0" :cols="4">
                  <label>{{ $t("StopEndDate") }}</label>
                  <el-date-picker
                   v-if="startItem && 
                   startItem.stop_subscription &&
                    startItem.stop_subscription.end_date &&
                     startItem.stop_subscription.end_date !== null
                     "
                    style="width: 100%"
                    :value="startItem.stop_subscription.end_date"
                    type="date"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                    readonly
                  >
                  </el-date-picker>
                   <v-else v-if="startItem && startItem.stop_subscription && startItem.stop_subscription.end_date == null">
                    <h4 class="mt-5" style="color:red">{{$t('NoStopEndDate')}}</h4>
                  </v-else>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStart = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            v-if="user && user.permissions && user.permissions.includes('start_subscribe')"
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStartConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    
       <!-- Start  Status Delete Dialog  -->
    <v-dialog v-model="dialogStatusDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="danger" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDeleteStatus") }} 
          </h3>
        </v-card-text>
        <v-container>
          <v-row>
               <v-col
              class="py-0"
              cols="12"
            >
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.cancellation_reason`),
                }"
              >
                <v-text-field
                  placeholder="سبب الإلغاء"
                  class="d-block my-2"
                  name="cancellation_reason"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="'سبب الإلغاء'"
                  hide-details
                  dense
                  outlined
                  v-model="cancellation_reason"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.cancellation_reason`)"
              >
                {{ errors.first(`addEditValidation.cancellation_reason`) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStatusDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteStatusItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
       <!-- End  Status Delete Dialog  -->


    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog :width="config.modalFormWidth" @click:outside="closeModal">
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-btn
             v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_subscribe')
              "
              color="info"
              @click="clearData"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              small
            >
              <span class="addNewLabel">{{ $t("AddNew") }}</span>

              <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
            </v-btn>
            <download-excel
              class="
                mb-2
                mx-5
                cursorPointer
                v-btn
                theme--dark
                v-size--small
                success
                v-btn v-btn--is-elevated v-btn--has-bg
              "
              :data="table.items"
              :fields="table.itemExport"
              type="xls"
              :header="$t(config.tableName)"
              :worksheet="$t(config.tableName)"
              :name="$t(config.tableName)"
            >
              <span class="excelLabel">{{ $t("ExportExcel") }}</span>
              <v-icon
                class="my-0 mr-2 ml-2"
                style="color: #fff !important"
                dense
                >mdi-export</v-icon
              >
            </download-excel>

            <v-btn
              color="danger"
              @click="loadPDF"
              dark
              small
              class="mb-2 mr-2 ml-2"
            >
              <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
              <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>
      </v-dialog>
    </v-toolbar>

    <!-- Start Filters -->
    <div>
      <v-row>
        <v-col :md="3">
          <div>
            <label>{{ $t("Name") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="customer_name"
              hide-details
              dense
              outlined
              v-model="filters.customer_name" 
              @input="updateParam($event,'customer_name')"
            ></v-text-field>
          </div>
        </v-col>
        <v-col :md="2">
          <div>
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="customer_name"
              hide-details
              dense
              outlined
              @input="updateParam($event,'customer_mobile')"
              v-model="filters.customer_mobile"
            ></v-text-field>
          </div>
        </v-col>

      <v-col :md="2">
        <div
        >
        <label>{{$t('City')}}</label>
          <v-autocomplete
            :clearable="true"
            class="d-block my-2"
            name="status"
            hide-details
            dense
            outlined
            :multiple="false"
            item-text="name"
            item-value="id"
            v-model="filters.city_id"
            @input="updateParam($event,'city_id');updateParam('','branch_id')"
            :items="cityList"
             @change="getNeighborhoodList(filters.city_id)"
          ></v-autocomplete>
        </div>
      </v-col>

      <v-col :md="2">
        <div
        >
        <label>{{$t('Neighborhood')}}</label>
          <v-autocomplete
            :clearable="true"
            class="d-block my-2"
            name="status"
            hide-details
            dense
            outlined
            :multiple="false"
            item-text="name"
            item-value="id"
            @input="updateParam($event,'branch_id')"
            v-model="filters.branch_id"
            :items="branchList"
          ></v-autocomplete>
        </div>
      </v-col>


        <v-col :md="1" class="mt-6">
          <v-btn
            color="third"
            hide-details
            @click="getDataFromApi"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Filter") }}
          </v-btn>
        </v-col>
        <v-col :md="1" class="mt-6">
          <v-btn
            color="danger"
            hide-details
            @click="clearFilters"
            style="display: block; margin-top: 10px"
            class="w-full h-btn white-clr"
            small
          >
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.subscribeIsEnded="{ item }">
        <p
          :style="`color:${item.subscribe_is_ended.color}`"
          v-if="item.subscribe_is_ended.is_ended_text"
          class="my-0"
        >
          {{ item.subscribe_is_ended.is_ended_text }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">

          <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="goDetails($event, item.customer_id)"
          style="color: purple !important; cursor: pointer; font-size: 22px"
          >mdi-eye</v-icon
        >

        
        <v-icon
          v-if="item.subscribe_is_ended.is_ended != 2 && item.subscribe_is_ended.is_ended != 3
          && user &&
          user.permissions &&
          user.permissions.includes('cancel_subscribe')
          "
          dense
          @click="
            formData = Object.assign({}, item);
            dialogStatusDelete = true;
            cancellation_reason= null;
            $validator.reset();
          "
           class="my-2 mr-2 ml-2"
          style="color: red !important; cursor: pointer; font-size: 24px"
          >mdi-close</v-icon
        >


        <v-icon
          v-if="item.subscribe_is_ended.is_ended == 1
              && user &&
                user.permissions &&
                user.permissions.includes('stop_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="stop(item)"
          style="color: red !important; cursor: pointer; font-size: 18px"
          >mdi-block-helper</v-icon
        >

        <v-icon
          v-else-if=" (item.subscribe_is_ended.is_ended == 0 || item.subscribe_is_ended.is_ended == 5)
          && user &&
                user.permissions &&
                user.permissions.includes('start_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="start(item)"
          style="color: green !important; cursor: pointer; font-size: 24px"
          >mdi-check-circle</v-icon
        >

         <v-icon
          v-else-if=" (item.subscribe_is_ended.is_ended == 0 || item.subscribe_is_ended.is_ended == 5)
          && user &&
                user.permissions &&
                (user.permissions.includes('button_edit_subscribe'))
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="start(item)"
          style="color: green !important; cursor: pointer; font-size: 24px"
          >mdi-check-circle</v-icon
        >

        <v-icon
          v-if="
            user &&
            user.permissions &&
            user.permissions.includes('store_subscribe')
          "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            $router.push({ name: 'subscribeaddDuplicate', params: { id: item.id } })
          "
          color="success"
          >mdi-plus</v-icon
        >


        <v-icon
            class="my-0 mr-2 ml-2"
            dense
            @click="
            $router.push({ name: 'subscribeEditAll', params: { id: item.id,customer_id : item.customer_id } })
          "
            color="success"
        >mdi-pencil</v-icon
        >

        <v-icon
        v-if="
                user &&
                user.permissions &&
                user.permissions.includes('delete_subscribe')
              "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "packages",
  data() {
    return {
         user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      cityList: [],
      branchList: [],
      filters: {
        customer_name: this.$route.query.customer_name || '',
        customer_mobile: this.$route.query.customer_mobile || '',
        city_id: this.$route.query.city_id || '',
        branch_id: this.$route.query.branch_id || '',
        // status: null,
        // group_id: null,
      },
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "StopSubscribesTable",
        crudHeader: "Subscribes",
        crudApi: "subscribe",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 2000,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          العميل: {
            field: "customer_name",
            callback: (item) => {
              if (item) return item;
            },
          },
           الموبايل: {
            field: "customer_mobile",
            callback: (item) => {
              if (item) return item;
            },
          },
           المدينة: {
            field: "city_name",
            callback: (item) => {
              if (item) return item;
            },
          },
           الحي: {
            field: "branch_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          الباقة: {
            field: "package_name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "من تاريخ": {
            field: "from_date",
            callback: (item) => {
              if (item) return item;
            },
          },
          "إلى تاريخ": {
            field: "to_date",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("CustomerName"),
            value: "customer_name",
            width: "8rem",
          },
          {
            text: this.$t("Mobile"),
            value: "customer_mobile",
            width: "7rem",
          },
          {
            text: this.$t("City"),
            value: "city_name",
            width: "7rem",
          },
          {
            text: this.$t("Neighborhood"),
            value: "branch_name",
            width: "7rem",
          },
          {
            text: this.$t("PackageName"),
            value: "package_name",
            width: "7rem",
          },
          {
            text: this.$t("StartDate"),
            value: "start_date",
            width: "7rem",
          },
          {
            text: this.$t("EndDate"),
            value: "end_date",
            width: "7rem",
          },
          {
            text: this.$t("Status"),
            value: "subscribeIsEnded",
            width: "7rem",
          },

        ],
      },
      formData: {
        id: 0,
      },
      stopForm: {
        start_date: null,
        end_date: null,
      },
      startForm: {
        date: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      stopItem: null,
      dialogStop: false,
      startItem: null,
      dialogStart: false,
       cancellation_reason: null,
       dialogStatusDelete: false,
    };
  },
  methods: {
    updateParam(value,key) {
      const query = { ...this.$route.query, [key]: value }
      const route = { ...this.$route, query }
      this.$router.replace(route)
    },
      goDetails(ev, id) {
       if (!ev.ctrlKey) {
         localStorage.setItem('customer_details_from', 'stop_subscribes');
         this.$router.push({ name: "customerDetails", params: { id: id } });
      } else {
        ev.preventDefault(); // Prevent the execution of the click event when the control key is held down
        localStorage.setItem('customer_details_from', 'stop_subscribes');
        const route = this.$router.resolve({ name: 'customerDetails', params: { id: id } });
        window.open(route.href, '_blank');
      }
     
    },
    stop(item) {
      this.$validator.reset();
      this.stopForm = {
        start_date: null,
        end_date: null,
      };
      this.stopItem = item;
      this.dialogStop = true;
    },
    start(item) {
      this.$validator.reset();
      this.startForm = {
        date: null,
      };
      this.startItem = item;
      this.dialogStart = true;
    },
    dialogStopConfirm() {
      let sendData = {
        id: this.stopItem.id,
        formData: JSON.parse(JSON.stringify(this.stopForm)),
      };
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.start_date)
          sendData.formData.start_date = moment(
            sendData.formData.start_date
          ).format("YYYY-MM-DD");

        if (sendData.formData.end_date)
          sendData.formData.end_date = moment(
            sendData.formData.end_date
          ).format("YYYY-MM-DD");

        this.$store
          .dispatch(`subscribe/stopSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StopSubscribe", res);
            this.dialogStop = false;
            this.getDataFromApi();
          })
      });
    },
    dialogStartConfirm() {
      let sendData = {
        id: this.startItem.id,
        formData: JSON.parse(JSON.stringify(this.startForm)),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.date)
          sendData.formData.date = moment(
            sendData.formData.date
          ).format("YYYY-MM-DD");

        this.$store
          .dispatch(`subscribe/startSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StartSubscribe", res);
            this.dialogStart = false;
            this.getDataFromApi();
          });
      });
    },
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      localStorage.setItem('addSubscribeFrom', 'StopSubscriptions');
      this.formData = {
        name: {
          ar: null,
          en: null,
        },
        price: null,
        cost: null,
        number_of_meals: null,
        number_of_days: null,
        details: [],
        days: [],
      };
      this.$router.push({ name: "subscribeCreate" });
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
     deleteStatusItemConfirm() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          return this.notifyCustomError("Error", "ValidationsErrors")
        }
        let sendData = {
          id: this.formData.id,
          cancellation_reason: this.cancellation_reason,
        }
        this.$store.dispatch(
          `${this.config.crudApi}/cancellationReason`,
          sendData
        ).then(() => {
          this.getDataFromApi();
          this.dialogStatusDelete = false;
        });
      });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.items = [];
      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getDataStop`,
        options,
        this.filters
      )
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
    getNeighborhoodList(id) {
      this.filters.branch_id= null;
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.branchList = res.data;
      });
    },
  },
  created() {
    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });
    if (this.$route.query.city_id) {
    this.filters.city_id = parseInt(this.$route.query.city_id);
    this.getNeighborhoodList(this.filters.city_id)
  }
    if (this.$route.query.branch_id) {
      this.filters.branch_id = parseInt(this.$route.query.branch_id);
    }
  },
  mounted() {
    this.getDataFromApi();

    if (
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("update_subscribe")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("delete_subscribe")) || 

        (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("start_subscribe")) || 

        (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("stop_subscribe")) ||

        (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("button_edit_subscribe"))
        
    )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "14rem",
      });
  },
};
</script>


<style>

.v-menu__content{
  z-index: 999 !important;
}
</style>